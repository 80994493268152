import React from "react";
import "./About.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import avatarImage from '../../Assets/img/avatar.png';

function About() {
  return (
    <>
      <Container className="about-us">
        <h2 className="heading-title">About Us</h2>
        <hr className="titler-hr " />
        <Row style={{ justifyContent: "space-between" }}>
          <Col lg={5}>
            <p className="about-para">
              "Hi, my name is Siddhant Bhandari. I'm a dedicated IT professional specializing in SQL Server database administration, 
              AWS Cloud Solutions, and big data analysis. I hold a 6-month PGDIP in Big Data Analytics certification from CDAC,
              and I am eager to continue exploring and enhancing my skills in the field."
            </p>
          </Col>
          <Col lg={5} className="my-test">
            <Image className="about-img framed" src={avatarImage} />
            {/* <Image
              id="fot"
              className="about-img framed"
              src="../images/fot.jpg"
            /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
