import React from "react";
import "./Resume.css";
import { Col, Container, Row } from "react-bootstrap";
import pdf from "../../Assets/img/CDAC_1.jpg";

function Resume() {
  return (
    <>
      <Container className="section-resume">
        <h2 className="heading-title">Resume</h2>
        <hr className="titler-hr " />
        <Row style={{ justifyContent: "space-between" }}>
          <Col xs={12} lg={5} className="inner-resume">
            <h4 >Experience</h4>
            <h5 id="resume-title">Huntsjob (Database Administrator)</h5>
            <p className="resume-para" >
              <ul className="my-ul">
                <li>
                Currently responsible for writing stored procedures to handle complex database operations for web applications, 
                ensuring seamless functionality.
                </li>
                <li>
                Actively managing AWS cloud architecture for the company's infrastructure, including ongoing setup and maintenance 
                of databases on AWS services. 
                </li>
                <li>
                Continuously implementing security measures and access controls in AWS to protect sensitive data
                stored in the cloud
                </li>
              </ul>
            </p>
            <br></br>
            <h5 id="resume-title">Metal Power Analytical Pvt. Ltd (Jr. Executive)</h5>
            <p className="resume-para" >
              <ul className="my-ul">
                <li>
                Played a pivotal role in the calibration process for research and development (R&D) by leveraging Atomic Spectroscopy
                Databases data.
                </li>
                <li>
                Implemented regression analysis techniques to streamline processes and ensure accurate alignment of wavelength and
                pixel ranges. 
                </li>
                <li>
                Conducted manual quality testing across process stages 2 to 5, rectifying errors prior to quality department handover.
                </li>
              </ul>
            </p>


            <a href={pdf} download="Siddhant_CDAC-Certificate">
            <button id="resume-btn" title="Click to See My Big Data Analyst Certification">View Certificate</button>
            </a>

          </Col>
          <Col xs={12} lg={5} className="inner-resume">
            <h4>Education</h4>
            <h5>Post Graduate Diploma </h5>
            <p className="resume-para">
              <ul className="my-ul">
                <li>
                  <h6>Big Data Analytics</h6>
                  <p>
                    Passing Year : <strong>2023</strong>
                  </p>
                  University : <strong>CDAC</strong>
                  <p></p>
                  <p>
                    CGPA : <strong>7.5/10</strong>
                  </p>
                  <p>
                    Grade : <strong>A</strong>
                  </p>
                  <p>
                    Location : <strong>Noida</strong>
                  </p>
                </li>
                <li>
                  <h6>Master of Science (M.Sc) </h6>
                  <p>
                    Graduation Year : <strong>2019</strong>
                  </p>
                  University : <strong>University Of Mumbai</strong>
                  <p>
                    CGPA : <strong>7.8/10</strong>
                  </p>
                  <p>
                    Location : <strong>Mumbai</strong>
                  </p>
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Resume;
