import React from "react";
import "./Skills.css";
import { Col, Container, Row } from "react-bootstrap";
import Cards from "../Cards/Cards";

function Skills() {
  return (
    <>
      <Container className="section-tech-stack">
        <h2 className="heading-title">Tech Stack</h2>
        <hr className="titler-hr " />
        <Row style={{ rowGap: "20px" }}>
          <Col xs={5} lg={2}>
            <Cards title="Cloud Hosting" img="host.png" />
          </Col>
          <Col xs={5} lg={2}>
            <Cards title="SQL Server" img="MSSQL.png" />
          </Col>
          <Col xs={5} lg={2}>
            <Cards title="tableau" img="tb.png" />
          </Col>
          
          <Col xs={5} lg={2}>
            <Cards title="Linux" img="linux.png" />
          </Col>
          
          <Col xs={5} lg={2}>
            <Cards title="Github" img="github.png" />
          </Col>

          <Row style={{ rowGap: "2px" }}></Row>

          <Col xs={5} lg={2}>
            <Cards title="Deep Learning" img="dl.png" />
          </Col>
          <Col xs={5} lg={2}>
            <Cards title="MongoDB" img="mongodb.png" />
          </Col>
          <Col xs={5} lg={2}>
            <Cards title="Python" img="python.png" />
          </Col>
          
          <Col xs={5} lg={2}>
            <Cards title="Hadoop" img="Hadoop.png" />
          </Col>
          <Col xs={5} lg={2}>
            <Cards title="Vscode" img="vscode.png" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Skills;
