import React from "react";
import "./Projects.css";
import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { BiLogoNetlify } from "react-icons/bi";
import { FaGithub } from "react-icons/fa";
import Carousels from "../Carousel/Carousels";
import { FaShoppingCart } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa6";
import CDC_1Img from "../../Assets/img/CDC_1.png";
import CDC_2Img from "../../Assets/img/CDC_1.png";
import CDC_3Img from "../../Assets/img/CDC_3.png";
import P1Img from "../../Assets/img/P1.png";
import P2Img from "../../Assets/img/P2.png";
import P3Img from "../../Assets/img/P1.png";


function Projects() {
  return (
    <>
      <Container className="section-projects">
        <h2 className="heading-title">Projects</h2>
        <hr className="titler-hr " />
        <Row className="project-row">
          <Col xs={12} lg={5}>
            <div className="title-icon-flx">
              <p className="project-title">Real-Time Data Capture and Seamless Replication with AWS</p>

            </div>
            <p className="project-description">
            <ul className="my-ul">
              <li>Led a groundbreaking project in Real-Time Data Capture and replication using AWS.</li>
              <li>Orchestrated seamless database interactions with SSMS for effortless data querying.</li>
              <li>Leveraged C# .NET API for robust data processing and analysis.</li>
              <li>Engineered resilient ETL processes with Glue Jobs for Full Load and Change Capture scenarios, enhancing data management efficiency.</li>
            </ul>
            </p>

          </Col>
          <Col xs={12} lg={5} className="test">
            <Carousels
              img1={CDC_1Img}
              img2={CDC_3Img}
              img3={CDC_2Img}

            />
          </Col>

          <Col xs={12} lg={5}>
            <div className="title-icon-flx">
              <p className="project-title">AWS-S3 Portfolio Deployment</p>
              {/* <FaDatabase color="#5EAC24" fontSize="30px" /> */}
            </div>
            <p className="project-description">
            <ul className="my-ul">
            <li>Configured an S3 bucket to host the portfolio website's static assets.</li>
            <li>Using Amazon Route 53, I associated a custom domain name with the S3 bucket, which improved the portfolio's professionalism.</li>
            <li>SSL/TLS encryption was implemented by procuring and installing an SSL certificate using AWS Certificate Manager, with the goal of prioritizing security for portfolio users.</li>
            </ul>
            </p>
            {/* <div className="project-icons">
              <div>
                <a href="https://rx-ecommerce.netlify.app/">
                  <BiLogoNetlify
                    className="hover-size"
                    size="30px"
                    color="#2196F3"
                  />
                </a>
                <span>View Live</span>
              </div>
              <div>
                <a href="https://github.com/viperven/rx-eccomerce">
                  <FaGithub
                    className="hover-size"
                    size="30px"
                    color="#2196F3"
                  />
                </a>
                <span>View Code</span>
              </div>
            </div> */}
          
          </Col>
          <Col xs={12} lg={5} className="test">
            <Carousels
              img2={P1Img}
              img1={P2Img}
              img3={P3Img}
            />
          </Col> 
        </Row>
      </Container>
    </>
  );
}

export default Projects;
