import React from "react";
import "./Testimonials.css";
import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import Carousels from "../Carousel/Carousels";
import cdacImage1 from '../../Assets/img/CDAC_1.jpg';
import cdacImage2 from '../../Assets/img/CDAC_2.jpg';
import cdacImage3 from '../../Assets/img/C3.png';



function Testimonials() {
  return (
    <>
      <Container className="section-appreciation">
        <h2 className="heading-title">Appreciations & Certifications</h2>
        <hr className="titler-hr " />

        <Row style={{ justifyContent: "space-between" }}>
          <Col xs={12} lg={5}>
            <Carousel className="my-shadow appreciation-carousel">
              <Carousel.Item style={{ textAlign: "center" }}>
                <Image
                  className="appreciation-image"
                  src={cdacImage1}
                />
              </Carousel.Item>
              <Carousel.Item style={{ textAlign: "center" }}>
                <Image
                  className="appreciation-image"
                  src={cdacImage2}
                />
              </Carousel.Item>
              <Carousel.Item style={{ textAlign: "center" }}>
                <Image
                  className="appreciation-image"
                  src={cdacImage3}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xs={12} lg={5}>
            <Carousel className="my-shadow appreciation-carousel">
              <Carousel.Item style={{ textAlign: "center" }}>
                <Image
                  className="appreciation-image"
                  src={cdacImage2}
                />
              </Carousel.Item>
              <Carousel.Item style={{ textAlign: "center" }}>
                <Image
                  className="appreciation-image"
                  src={cdacImage3}
                />
              </Carousel.Item>
              <Carousel.Item style={{ textAlign: "center" }}>
                <Image
                  className="appreciation-image"
                  src={cdacImage1}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Testimonials;
