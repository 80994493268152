import React from "react";
import "./Cards.css";
import Card from "react-bootstrap/Card";

import hostImg from "../../Assets/img/host.png";
import msqlImg from "../../Assets/img/MSSQL.png";
import mongodbImg from "../../Assets/img/mongodb.png";
import hadoopImg from "../../Assets/img/Hadoop.png";
import linuxImg from "../../Assets/img/linux.png";
import pythonImg from "../../Assets/img/python.png";
import dlImg from "../../Assets/img/dl.png";
import githubImg from "../../Assets/img/github.png";
import csharpImg from "../../Assets/img/Csharp.png";
import tableauImg from "../../Assets/img/tb.png";
// import powerbiImg from "../../Assets/img/pb.png";
import vscodeImg from "../../Assets/img/vscode.png";

function Cards({ title }) {
  const imageMap = {
    "Cloud Hosting": hostImg,
    "SQL Server": msqlImg,
    "MongoDB": mongodbImg,
    "Hadoop": hadoopImg,
    "Linux": linuxImg,
    "Python": pythonImg,
    "Deep Learning": dlImg,
    "Github": githubImg,
    "Csharp": csharpImg,
    "tableau": tableauImg,
    // "PowerBI": powerbiImg,
    "Vscode": vscodeImg,
    // Add other mappings here
  };

  const imagePath = imageMap[title];

  return (
    <Card className="my-card">
      <Card.Img variant="top" src={imagePath} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default Cards;
